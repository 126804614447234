
import {onMounted, ref, watch} from "vue";
import useTransferBetween from "@/modules/pettyCash/composables/useTransferBetween";
import usePanel from "@/composables/usePanel";
import useSelect2 from "@/composables/useSelect2";

const {transferBetweenForm} = useTransferBetween()

export default {
    props: ['catalogs'],
    setup() {
        const {showPanel, togglePanel} = usePanel()
        const {select2pettycash} = useSelect2(`?isLeaf=1`)

        const pettyCashDestinyId = ref('')
        const amount = ref(0)
        const date = ref('')

        onMounted(() => {
            pettyCashDestinyId.value = transferBetweenForm.value.pettyCashDestinyId
            amount.value = transferBetweenForm.value.amount
            date.value = transferBetweenForm.value.date
        })

        watch(pettyCashDestinyId, val => transferBetweenForm.value.pettyCashDestinyId = val)
        watch(amount, val => transferBetweenForm.value.amount = val)
        watch(date, val => transferBetweenForm.value.date = val)

        return {
            select2pettycash,
            showPanel,
            togglePanel,
            pettyCashDestinyId,
            amount,
            date,
            transferBetweenForm
        }
    }
}
