import useAuth from "@/modules/auth/composables/useAuth";
import {Ref, ref} from "vue";
import {v4 as uuid} from "uuid";
import {TransferBetweenForm} from "@/modules/pettyCash/interfaces"
import store from "@/store";
import moment from "moment";

const {companyId} = useAuth()
const defaultValues = (): TransferBetweenForm => {
    return {
        id: uuid(),
        pettyCashId: "",
        pettyCashDestinyId: "",
        amount: 0,
        date: moment().format('DD/MM/YYYY'),
        companyId: companyId.value
    }
}

const transferBetweenForm: Ref<TransferBetweenForm> = ref(defaultValues())

const useBetweenTransfer = () => {
    const catalogs = {};
    const clearForm = () => transferBetweenForm.value = defaultValues()

    const createTransferBetween = async (transferBetweenForm: TransferBetweenForm) => {
        return await store.dispatch('pettyCash/createTransferBetween', transferBetweenForm)
    }

    const updateTransferBetween = async (transferBetweenForm: TransferBetweenForm) => {
        return await store.dispatch('pettyCash/updateTransferBetween', transferBetweenForm)
    }

    const getTransferBetween = async (id: string): Promise<TransferBetweenForm> => {
        const {data} = await store.dispatch('pettyCash/getTransferBetween', id)

        return data;
    }


    return {
        transferBetweenForm,
        catalogs,
        clearForm,
        createTransferBetween,
        updateTransferBetween,
        getTransferBetween
    }
}

export default useBetweenTransfer;
